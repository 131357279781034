import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_WINDOW_VALUES: (state, payload) => {
      return {
        uiStore: {
          ...state.uiStore,
          windowValues: payload,
        }
      }
    },

  };

  initStore(actions, {
    uiStore: {
      windowValues: null,
    }
  });
};

export default configureStore;
