import React, { Fragment, useEffect, useState } from "react";
// import { withI18n } from "react-i18next";

import { storeAdVideoViewVisit } from '../../../util/ad/ad-visit';

import { useStore } from "../../../hook-store/store";

import { NODEURL, ADNETWORKLINK_URL } from "../../../App";

// import GrayImage from '../../../../images/light-gray-square-300.jpg';
import remeetImage1 from '../../../images2/webinar-100.png';
import RemeetAdVideo from '../../../images2/remeet-test2-2-darkintro.mp4';

import { marks } from "../../../util/marks";
import classes from "./AdItems.module.css";
// import "../FeedEdit.css";
// import "./ImagePreviewContents.css"

const VideoAdItems = (props) => {
  // console.log("VideoAdItems.js-props", props);
  const { 
    ad, 
    adType, 
    activeList, 
    isVisible,
    setPlayState,

    adPlaceId,

    playCurrentTime,
    setPlayCurrentTime,
  } = props;

  const [store, dispatch] = useStore();
  const videoAdList = store.adStore.videoAdList;

  const [listenStart, setListenStart] = useState(false);
  const [fallbackDesc, setFallbackDesc] = useState(null);

  const [isMinSec, setIsMinSec] = useState(false);
  // const [playCurrentTime, setPlayCurrentTime] = useState(0);

  const [isClicked, setIsClicked] = useState(false);
  const [isMute, setIsMute] = useState(true);


  // // play and pause controll on visible state
  useEffect(() => {
    const videoEl = document.getElementById('adVideo');
    
    if (ad && adType && activeList && videoEl && isVisible) {
      videoEl.play();
      console.log('playing... isVisible', isVisible);

      // setTimeout(() => {
      // },5000);
    }

    if (ad && adType && activeList && videoEl && !isVisible) {
      videoEl.pause();
      console.log('paused... isVisible', isVisible);
      // setTimeout(() => {
      // },5000);
    }

  },[ad, adType, activeList, isVisible]);

  // // listen video playing state
  useEffect(() => {
    const videoEl = document.getElementById('adVideo');

    if (ad && adType && activeList && videoEl && !listenStart) {
      videoEl.addEventListener('ended', (e) => {
        console.log('ended');
        // videoEl.play();
        setPlayState('ended');
      });

      videoEl.addEventListener('playing', (e) => {
        console.log('playing...');
        setPlayState('playing');
      });

      setListenStart(true);
    }

    // // detect updated currentTime
    videoEl.addEventListener("timeupdate", function (e) {
      // console.log('timeupdate currentTime', e.target.currentTime);
      if (activeList.length > 0 && ad && adType) {
        setPlayCurrentTime(e.target.currentTime);
      }

      if (e.target.currentTime > 10) {
        setIsMinSec(true);
      }
    });
    
  },[ad, adType, activeList, listenStart]);


  useEffect(() => {
    if (isMinSec && !isClicked) {
      //// store view data;
      adVideoViewClickHandler(ad, '');
    }
  },[isMinSec, isClicked]);


  // // display fallback desc when adlist not exist 
  useEffect(() => {
    if (!ad && activeList.length === 0 && adType === 'video300') {
      setTimeout(() => {
        setFallbackDesc(
          <div className="body300x300TextContainer">
            <span className="body300x300TextTitle">
              Video Talk & Meeting
            </span>
            <span className="body300x300TextDescription">
              REMEET: Web meetings & talks with text chat, file & screen sharing
            </span>
          </div>
        );
      },1000*10);
    }

  },[ad, adType, activeList]);


  const videoMuteHandler = (index) => {
    const videoEl = document.getElementById('adVideo');
    
    if (videoEl.muted) {
      videoEl.muted = false;
      setIsMute(false);
    } else {
      videoEl.muted = true;
      setIsMute(true);
    }
  };

  // const handlePlayHandler = () => {
  //   const videoEl = document.getElementById('adVideo');

  // }
  // const pauseHandler = (index) => {
  //   const videoEl = document.getElementById('adVideo');
  //   videoEl.pause();
  // };
  
  // const playHandler = (index) => {
  //   const videoEl = document.getElementById('adVideo');
  //   videoEl.play();
  // };

  // console.log(document.getElementById('adVideo'))
  

  const adVideoViewClickHandler = async (ad, click) => {
    try {
      if (isClicked) {
        console.log('already store visit');
        return;
      }

      if (isVisible && activeList.length > 0 && ad && adType) {
        setIsClicked(true);

        await storeAdVideoViewVisit(
          NODEURL, 
          localStorage.getItem('token'), 
          ad.adElementId, 
          adPlaceId, 
          adType,

          '',
          adType,
          playCurrentTime,
          click,

        );

      }
    } catch(err) {
      console.log(err);

      if (err.message === 'budget-error' && ad) {
        //// adlist change (video)
        const deletedList = videoAdList.filter(vad => {
          return vad.adElementId !== ad.adElementId;
        });

        dispatch('SET_VIDEOADLIST', deletedList);
      }
    }

  };


  const videoEl = document.getElementById('adVideo');
  let bodyVideo300;

  const playInfo = (
    <span 
      style={{
        position:"absolute", 
        top: "0px", 
        left: "10px",
        color:"white",
        background: "black",
        opacity: "0.6",
        fontSize: "12.5px",
      }}
    >
      {videoEl && videoEl.duration > 0 && (
        (videoEl.duration - playCurrentTime).toFixed()
      )}
    </span>
  );

  const soundControlBody = (
    <span 
      style={{
        position:"absolute", 
        top: "14px", 
        left: "10px",
        fontSize: "25px", 
        color:"white",
        background: "black",
        opacity: "0.6",
        // padding: "2px",
        zIndex: "100",
        cursor: "pointer",
      }}
    >
    <span 
      onClick={videoMuteHandler}
    >
      {isMute && (
        <span>{marks.volumeUpMark}</span>
      )}
      {!isMute && (
        <span>{marks.volumeOffMark}</span>
      )}
    </span>
  </span>
  );

  if (ad && activeList && activeList.length > 0) {
    bodyVideo300 = (
      <div style={{position:"relative"}}>
        <a className={classes.adLink}
          // href={ad.linkUrl}
          href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
          target="_blank" rel="noopener noreferrer"
          onClick={() => { adVideoViewClickHandler(ad, 'click'); }}
        >
          <video 
            // className="body300x300Image"
            className="body300Video"
            id='adVideo'
            src={ad.adImageUrl}
            // controls
            // autoPlay
            muted
            alt="ad video"
          />
        </a>
        {playInfo}
        {soundControlBody}
      </div>
    )
  }
  else {
    // // some fallback
    bodyVideo300 = (
      <div style={{position:"relative"}}>
        <a className={classes.adLink}
          // href={ad.linkUrl}
          href={`https://remeet.watakura.xyz/your-room-from-above-link`}
          target="_blank" rel="noopener noreferrer"
        >
          <video 
            // className="body300x300Image"
            className="body300Video"
            id='adVideo'
            src={RemeetAdVideo}
            // controls
            autoPlay
            loop
            muted
            alt="ad video"
          />
          {fallbackDesc}
        </a>
        {/* {playInfo}
        {soundControlBody} */}
      </div>


        
    )
  }



  let adItemsBody;

  if (adType === 'video300') {
    adItemsBody = (
      bodyVideo300
    );
  }
  

  return (
    <Fragment>
      {adItemsBody}
    </Fragment>
  );
};

export default VideoAdItems;
