export const calcVideoSize = (
  vHeight, 
  vWidth, 
  ifVHeight, 
  ifVWidth,
  isBannerAds,
) => {
  // console.log(vHeight, vWidth, ifVHeight, ifVWidth, isBannerAds);

  //// frame size is larger case
  if (ifVWidth > vWidth && ifVHeight > vHeight) {

    // console.log(ifVHeight/ifVWidth, vHeight/vWidth);

    if (ifVHeight/ifVWidth > vHeight/vWidth) {
      // console.log('width fit case')

      return {
        position: "absolute",
        minWidth: "100%",
        // top: 0,
        bottom: 0,
        left: 0,
      }

      // if (isBannerAds) {
      //   return {
      //     position: "absolute",
      //     minWidth: "100%",
      //     // top: 0,
      //     bottom: 0,
      //     left: 0,
      //   }
      // }

      // return {
      //   // marginTop: `${(ifVHeight - vHeight)/2}px`,
      //   // marginLeft: `${(ifVWidth - vWidth)/2}px`,
      //   position: "absolute",
      //   minWidth: "100%",
      //   // minHeight: "100%",
      //   // width: "auto", 
      //   // height: "auto", 

      //   top: 0,
      //   bottom: 0,
      //   margin: "auto 0",
      //   left: 0,
      // }

    } else {
      // console.log('height fit case')
      return {
        // marginTop: `${(ifVHeight - vHeight)/2}px`,
        // marginLeft: `${(ifVWidth - vWidth)/2}px`,
        position: "absolute",
        // minWidth: "100%",
        minHeight: "100%",
        // width: "auto", 
        // height: "auto",

        left: 0,
        right: 0,
        margin: "0 auto",

        top: 0,
      }
    }


  } else {
    //// frame size is smaller case

    if (vHeight/vWidth <= ifVHeight/ifVWidth) {
      // console.log('vHeight/vWidth <= ifVHeight/ifVWidth')
      return {
        position: 'absolute',
        maxHeight: '100%',
        maxWidth: '100%',
        // top: 0,
        bottom: 0,
        left: 0,
      }
      
      // if (isBannerAds) {
      //   return {
      //     position: 'absolute',
      //     maxHeight: '100%',
      //     maxWidth: '100%',
      //     // top: 0,
      //     bottom: 0,
      //     left: 0,
      //   }
      // }
      
      // return {
      //   position: 'absolute',
      //   // marginTop: `${Math.sqrt(( (ifVHeight - ifVWidth * vHeight/vWidth)/2 )**2)}px`,
      //   // marginLeft: 0,
      //   maxHeight: '100%',
      //   maxWidth: '100%',

      //   top: 0,
      //   bottom: 0,
      //   margin: "auto 0",
      //   // left: 0,
      //   // right: 0,
      //   // margin: "0 auto",
      //   left: 0,
      // }

    }

    if (vHeight/vWidth >= ifVHeight/ifVWidth) {
      // console.log('vHeight/vWidth >= ifVHeight/ifVWidth')
      return {
        position: 'absolute',
        // marginTop: 0,
        // marginLeft: `${Math.sqrt(((ifVWidth - ifVHeight * vWidth/vHeight)/2) **2)}px`,
        maxHeight: '100%',
        maxWidth: '100%',

        // top: 0,
        // bottom: 0,
        // margin: "auto 0",
        left: 0,
        right: 0,
        margin: "0 auto",

        top: 0,
      }
    }

  }

 };



export const toHHMMSS = function (duration) {
  var sec_num = parseInt(duration, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {
    hours   = "0"+hours;
  }
  if (minutes < 10) {
    minutes = "0"+minutes;
  }
  if (seconds < 10) {
    seconds = "0"+seconds;
  }

  // console.log('hours', hours, minutes, seconds);

  if (hours === "00") {
    return +minutes+':'+seconds;
  } else {
    return hours+':'+minutes+':'+seconds;
  }
}