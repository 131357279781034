
import  { Fragment, useRef, useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { useStore } from '../../../../hook-store/store';
// import { storeAdDisplay } from '../../../../util/ad/ad-display';
// import { storeAdVideoViewVisit } from '../../../../util/ad/ad-visit';
// import { calcVideoSize, toHHMMSS } from '../../../../util/ad/video-with-ad';

// import { NODEURL } from '../../../../App';
import { marks } from '../../../../util/marks';

const VideoAdVolume = props => {
  const { 
    items, 
    itemsRef,
    vIndex,
    isMute,
    defaultVolume,
    muteHandler,
  } = props;
  // console.log('VideoOverElement-props', props);

  const [store, dispatch] = useStore();
  // console.log('store-in-VideoAdVolume', store)
  // const { videoVolume } = store.videoStore;

  // console.log(window.innerHeight, window.innerWidth);
  const [sliderValue, setSliderValue] = useState(defaultVolume);
  const [showSlider, setShowSlider] = useState(false);
  const [beforeMuteVolume, setBeforeMuteVolume] = useState(defaultVolume);
  
  // useEffect(() => {
  //   setSliderValue(defaultVolume);
  // },[]);

  useEffect(() => {
   if (isMute) {
    setSliderValue(0.01);
   } else {
    //  const videoEl = itemsRef.current[vIndex];
   }
  },[isMute]);

  useEffect(() => {
    const volumeEl = document.getElementById('advolume-mark');

    volumeEl.addEventListener('mouseover', (event) => {
      setShowSlider(true);
    });

    volumeEl.addEventListener('mouseout', (event) => {
      setShowSlider(false);
    });

  },[]);

  const onSliderChange = (value) => {
    console.log(value);
    setSliderValue(value);

    const videoEl = itemsRef.current[vIndex];

    if (value > 0.01) {
      videoEl.volume = value;
      videoEl.muted = false;
    }

    if (value === 0.01) {
      // videoEl.volume = 0;
      videoEl.muted = true;

      // setShowSlider(false);
    }
  };


  return (
  <Fragment>
    <div
      id='advolume-mark'
      style={{ 
        fontSize: "30px", 
        width: showSlider ? "" : "20px",
        cursor: "pointer", 
        display: "flex",
        alignItems: "center",
        gap: "10px",
        // paddingRight: "10px",
      }}
    >
      <div>
        <span
          style={isMute ? { color: "lightgray" } : null}
          onClick={() => {
            setShowSlider(!showSlider);
          }}
        >
          {marks.volumeOffMark}
        </span>
      </div>

      {showSlider && (
        <div
        style={{ 
          padding: "0 10px 5px 10px",
          width: "100px"
         }}
        >
          <Slider 
            value={sliderValue}
            min={0.01}
            max={1}
            step={0.05}
            onChange={onSliderChange}
            // defaultValue={0.3}
            // startPoint={0.2}
          />
        </div>
      )}
    </div>
  </Fragment>
);
}

export default VideoAdVolume;