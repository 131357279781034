// import logo from './logo.svg';
// import './App.css';
import { Fragment } from "react";

import AdPage from "./components/AdPage";
import GetWindowData from "./components/UI/GetWindowData";
// import GetAd from './components/AdElements/GetAds/GetAds';



//// dev urls
// export const ADNETWORKLINK_URL = process.env.REACT_APP_DEV_ADNETLINK_URL;
// export const NODEURL = process.env.REACT_APP_DEV_NODEURL;


//// test deploy urls
// export const ADNETWORKLINK_URL = process.env.REACT_APP_TEST_ADNETLINK_URL;
// export const NODEURL = process.env.REACT_APP_TEST_NODEURL;


//// do urls deploy
export const ADNETWORKLINK_URL = process.env.REACT_APP_ADNETLINK_URL;
export const NODEURL = process.env.REACT_APP_NODEURL;


function App() {
  return (
    <Fragment>
      <AdPage />
      <GetWindowData />
      {/* <GetAd /> */}
    </Fragment>
  );
}

export default App;
