export const storeAdDisplay = async (
	url, 
	token, 
	adElementId, 
	adPlaceId,

	//// for ad video videoWithAd
	dispId,
	displayType,
	vIndex,
	bDisplayStart,
	bIndex,
) =>
{
	try
	{
		// eslint-disable-next-line max-len
		// const lsUserLocation = localStorage.getItem('userLocation') ? localStorage.getItem('userLocation') : '';
		// const lsUserSelectLng = localStorage.getItem('userSelectLng')
		// 	? localStorage.getItem('userSelectLng')
		// 	: navigator.language;

		// eslint-disable-next-line max-len
		// const response = await fetch(url + `/ad-display/store-ad-display?userLocation=${lsUserLocation}&selectLanguage=${lsUserSelectLng}`, {
		// eslint-disable-next-line max-len
		// const response = await fetch(`${url }/ad/ad-display?userLocation=${lsUserLocation}&selectLanguage=${lsUserSelectLng}`, {
		const response = await fetch(`${url }/ad/ad-display`, {
			method  : 'POST',
			headers : {
				Authorization  : `Bearer ${ token}`,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				adElementId : adElementId,
				adPlaceId   : adPlaceId,
				dispId: dispId,
				displayType: displayType,
				vIndex: vIndex,
				bDisplayStart: bDisplayStart,
				bIndex: bIndex,
			})
		});

		// eslint-disable-next-line no-console
		console.log(response);
		if (response.ok)
		{
			const resData = await response.json();

			// eslint-disable-next-line no-console
			console.log(resData);

			return resData;
			// resolve(resData);
		}
		else
		{
			// reject({ message: 'get ads failed' });
			throw new Error('something wrong');
		}
	}
	catch (err)
	{
		// eslint-disable-next-line no-console
		console.log(err);
		// reject(err);
	}

};