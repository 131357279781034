import { useEffect, useState, Fragment } from "react";

import { NODEURL } from "../App";

import AdElementDisplay from './AdElements/AdElememtDisplay/AdElementDisplay';
import GetAds from './AdElements/GetAds/GetAds';
import GetVideoAds from "./AdElements/GetAds/GetVideoAds";
import VideoAdElementTime from "./AdElements/AdElememtDisplay/VideoAdElementTime";
import VideoWithAdDisplay from "./AdElements/AdElememtDisplay/VideoWithAdDisplay";

function getParentUrl() {

  let parentUrl = null;
  var isInIframe = (window.parent !== window);

  if (isInIframe) {
      parentUrl = document.referrer.substring(0, document.referrer.length -1);
  }

  // console.log('window.parent', window.parent);
  // console.log('window', window);
  // console.log('isInIfram', isInIframe);
  // console.log('parentUrl', parentUrl);
  
  return parentUrl;
}

const curUrl = new URL(window.location.href);
const tokenParam = curUrl.searchParams.get('altk');
// const adType = curUrl.searchParams.get('adType');
const adPlaceId = curUrl.searchParams.get('adPlaceId');
const isClicked = curUrl.searchParams.get('isClicked');

// const host = curUrl.searchParams.get('host');


let parentUrl;
let parentHost;

if (getParentUrl()) {
  parentUrl = new URL(getParentUrl())
  parentHost = parentUrl.host;
}

let splitedPlaceId = adPlaceId ? adPlaceId.split('-') : [];
let popped = splitedPlaceId.pop();
const parentHostFromPlaceId = splitedPlaceId.join('-');
console.log(parentHostFromPlaceId);

console.log('parentUrl', parentUrl, parentHost);
// console.log('adType, adPlaceId',adType, adPlaceId);
// console.log('p purl', parentUrl === parentUrlFromPlaceId);
// console.log(curUrl);






function AdPage(props) {

  const [adHostData, setAdHostData] = useState();
  const [adType, setAdType] = useState('');

  useEffect(() => {

    console.log(parentHost, parentHostFromPlaceId);
    if (parentHost === parentHostFromPlaceId) {
      getAdHostDataHandler(
        NODEURL,
        '',
        adType,
        adPlaceId,
      );
    }

  }, []);

  const getAdHostDataHandler = async (url, token, adType, adPlaceId) => {
    try {
      const response = await fetch(
        `${url}/ad/ad-host?adPlaceId=${adPlaceId}&adType=${adType}`,
        {
          method: "GET",
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // eslint-disable-next-line no-console
      console.log(response);
      if (response.ok) {
        const resData = await response.json();

        // eslint-disable-next-line no-console
        // console.log('getAdHostDataHandler resData', resData);

        const dbAdHostData = resData.data;

        if (dbAdHostData.adStatus !== 'not-approved') {
          setAdHostData(dbAdHostData);
          setAdType(resData.data.adType);
        }
        // console.log('dbAdHostData', dbAdHostData);
        
        return resData;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      // reject(err);
    }
  };



  let adElementBody;

  if (adType === '300x65') {
    adElementBody = (    
      <AdElementDisplay
        adType='300x65'
        adPlaceId={adPlaceId}
      />
    );
  }

  if (adType === '450x90') {
    adElementBody = (    
      <AdElementDisplay
        adType='450x90'
        adPlaceId={adPlaceId}
      />
    );
  }

  if (adType === '600x130') {
    adElementBody = (    
      <AdElementDisplay
        adType='600x130'
        adPlaceId={adPlaceId}
      />
    );
  }

  if (adType === '150x500') {
    adElementBody = (    
      <AdElementDisplay
        adType='150x500'
        adPlaceId={adPlaceId}
      />
    );
  }

  if (adType === '300x300') {
    adElementBody = (    
      <AdElementDisplay
        adType='300x300'
        adPlaceId={adPlaceId}
      />
    );
  }

  if (adType === 'video300') {
    adElementBody = (
      <VideoAdElementTime
        adType='video300'
        adPlaceId={adPlaceId}
      />
    );
  }

  if (adType === 'videoWithAd') {
    adElementBody = (
      <VideoWithAdDisplay
        adType='videoWithAd'
        adPlaceId={adPlaceId}
        adHostData={adHostData}
      />
    )
  }

  // if (adType === 'inPosts') {
  //   adElementBody = (    
  //     <AdElementDisplay
  //       adType='inPosts'
  //       adPlaceId={adPlaceId}
  //     />
  //   );
  // }

  return <Fragment>
    {adType && adHostData && parentHost === parentHostFromPlaceId && (
      <div>
        {adElementBody}
        {adType.startsWith('video') && (
          <div>
            <GetVideoAds />
            <GetAds />
          </div>
        )}
        {!adType.startsWith('video') && (
          <GetAds />
        )}
      </div>

    )}

    {(!adHostData || parentHost !== parentHostFromPlaceId) && (
      <div style={{display: 'none'}}>
        <a href='https://adnetwork.spaceeight.net'>
          adnetwork-home-page
        </a>
        {/* <a href=''>
          adnetwork-adhost-home-page
        </a> */}
      </div>
    )}
    
    
    </Fragment>;
}

export default AdPage;
