// import _ from 'lodash';

import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_VIDEOVOLUME: (state, payload) => {
      return {
        ...state,
        videoStore: {
          ...state.videoStore,
          videoVolume: payload,
        }
      };
    },
  };

  initStore(actions, {
    // notification: null,
    videoStore: {
      videoVolume: 0,
    }
  });
};

export default configureStore;
