import React, { Fragment, useState, useEffect } from 'react';
// import { useTranslation } from "react-i18next/hooks";
// import bowser from 'bowser';

import GetAdList from './GetAdList';

// import { getNearAdElements } from "../../../../util/ad-visit";
import { useStore } from '../../../hook-store/store';
import * as adVisitUtils from '../../../util/ad/ad-visit';
import { NODEURL } from '../../../App';

// window.BB = bowser;
// const ua = navigator.userAgent;
// const browser = bowser.getParser(ua);
// const parsedResult = browser.parsedResult;

const GetAds = (props) =>
{
	// console.log('GetAdList-props', props);
	// const [ t ] = useTranslation('translation');

	const [ store, dispatch ] = useStore();
	// console.log('store GetAds.js', store);

	const [ isTimerStart, setIsTimerStart ] = useState(false);
	// const [timerTime, setTimerTime] = useState(0);


	// useEffect(() => {
	//   setTimeout(() => {
	//     if (store.adStore.adList.length === 0) {
	//       getNearAdElementsHandler();
	//     }
	//   }, 1000 * 60);
	// }, []);

	useEffect(() =>
	{
		if (!isTimerStart)
		{
			setIsTimerStart(true);

			setInterval(() =>
			{
				// setTimerTime(Date.now());

				// eslint-disable-next-line no-use-before-define
				getNearAdElementsHandler();
			}, 1000 * 60 * 15);
		}
	}, []);

	// useEffect(() => {
	//   console.log('timerTime', timerTime);
	//   const lastGetTime = store.adStore.adListGetDate;
	//   console.log('adListGetDate', lastGetTime);
	//   if (lastGetTime && (timerTime - lastGetTime) > 1000 * 60 * 15) {
	//     console.log("15min");
	//     // getNearAdElementsHandler();
	//   }

	// },[timerTime]);

	const getNearAdElementsHandler = async () =>
	{
		try
		{
			console.log('NODEURL', NODEURL);
			// const adsData = await getNearAdElements(ADNETWORK_URL, "token");
			const adsData = await adVisitUtils.getNearAdElements(NODEURL, 'token');

			// eslint-disable-next-line no-console
			// console.log(adsData);
			// setAdList(adsData.data.ads);

			let adList = adsData.data.ads;

			// // filter not end ads and device size
			if (adList.length > 0)
			{
				// adList = adList.filter((ad) =>
				// {
				// 	return ad.end > Date.now();
				// 	// return ad.start < Date.now() && ad.end > Date.now();
				// });

				if (window.innerWidth <= 480)
				{
					adList = adList.filter((ad) =>
					{
						return ad.targetDevice !== 'desktop' && ad.end > Date.now();
					});
				}
				if (window.innerWidth > 480)
				{
					adList = adList.filter((ad) =>
					{
						return ad.targetDevice !== 'mobile' && ad.end > Date.now();
					});
				}
			}

			dispatch('SET_ADLIST', adList);
		}
		catch (err)
		{

			// eslint-disable-next-line no-console
			console.log(err);
		}
	};

	return (
		<Fragment>
			<GetAdList />
		</Fragment>
	);
};

export default GetAds;
